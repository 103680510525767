import React, { useEffect, useState } from "react";
import styles from "./location.module.sass";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { useFormik } from "formik";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import cn from "classnames";
import { faEdit, faTrash, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { API_BASE_URL } from "../../config";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .css-17jjc08-MuiDataGrid-footerContainer": {
    display: "none",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  py: 5,
  px: 3,
};
const styleCircular = {
  position: "absolute",
  top: {
    xs: "50%",
  },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20px",
  height: "20px",
};

const Location = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [locationsTotal, setLocationsTotal] = useState(1);
  const [locationsLimit, setLocationsLimit] = useState(1);
  const [locationsData, setLocationsData] = useState("");
  const [selectedRow, setSelectedRow] = useState({ name: "", id: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [locationName, setLocationName] = useState("");
  const [page, setPage] = useState(1);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const updateLocationHandler = async (e) => {
    e.preventDefault();
    console.log("form category updated cn=> ", selectedRow.locationId, selectedRow.name);
    try {
      const updatedlocationData = {
        name: selectedRow.name,
        isActive: isChecked,
      };

      const res_data = await axios.put(
        `${API_BASE_URL}/api/ns/locations/${selectedRow.locationId}`,
        updatedlocationData
      );
      console.log("res_location_updated_data => ", res_data);
      if (res_data.status === 200) {
        handleClose();
        Swal.fire({
          title: "Success!",
          text: " Location Updated Successfully",
          icon: "success",
          confirmButtonText: "Close",
        });

        setTimeout(() => getLocationsHandler(), 200);
      }
    } catch (e) {
      console.log(e);
      handleClose();
      Swal.fire({
        title: "Error!",
        text: "Failed to update",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const deleteLocationHandler = async (id) => {
    console.log("delete Location Handler id => ", id);
    try {
      const res_data = await axios.delete(`${API_BASE_URL}/api/ns/locations/${id}`);
      console.log("res_location_deleted_data => ", res_data);
      if (res_data.status === 200) {
        Swal.fire({
          title: "Success!",
          text: " Location Deleted Successfully",
          icon: "success",
          confirmButtonText: "Close",
        });

        setTimeout(() => getLocationsHandler(), 200);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Error!",
        text: "Failed to delete",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const columns = [
    {
      field: "locationId",
      headerName: "ID",
      width: 150,
    },
    {
      field: "name",
      headerName: "Location Name",
      width: 240,
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 140,
    },
    {
      field: "postedCount",
      headerName: "Posted Count",
      width: 140,
    },
    {
      field: "failedCount",
      headerName: "Failed Count",
      width: 140,
    },
    {
      field: "successRatio",
      headerName: "Success Ratio",
      width: 140,
    },
    {
      field: "failedRatio",
      headerName: "Failed Ratio",
      width: 140,
    },
    {
      field: "totalCount",
      headerName: "Total Count",
      width: 140,
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <button
              className={cn(styles.action_edit_btn)}
              onClick={() => {
                setSelectedRow(params.row);
                setIsChecked(params.row.isActive);
                handleOpen();
              }}
            >
              <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />
            </button>
            <button
              className={cn(styles.action_delete_btn)}
              onClick={() => deleteLocationHandler(params.row.locationId)}
            >
              <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} />
            </button>
          </>
        );
      },
    },
  ];

  const searchHandler = async (locationName) => {
    setLocationName(locationName);
    console.log("search location name value ", locationName);
    try {
      const isActiveQuery = isActive.length > 0 && isActive !== "Both" ? `isActive=${isActive}` : "";
      const response = await axios.get(`${API_BASE_URL}/api/locations?${isActiveQuery}&name=${locationName}`);
      console.log("res_data.data location => ", response?.data);
      setLocationsTotal(response?.data?.total);
      setLocationsLimit(response?.data?.limit);
      if (response?.data?.results) {
        let newres_data = response?.data?.results.map((location, i) => {
          return { id: i + 1, ...location };
        });
        console.log("newres_data location => ", newres_data);
        setLocationsData(newres_data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isActiveHandler = async (event) => {
    console.log("isActive filter value ", event.target.value);
    setIsActive(event.target.value);
    try {
      const isActiveQuery = event.target.value === "Both" ? "" : `isActive=${event.target.value}`;
      const response = await axios.get(`${API_BASE_URL}/api/locations?${isActiveQuery}&name=${locationName}`);
      console.log("res_data.data location => ", response?.data);
      setLocationsTotal(response?.data?.total);
      setLocationsLimit(response?.data?.limit);
      if (response?.data?.results) {
        let newres_data = response?.data?.results.map((location, i) => {
          return { id: i + 1, ...location };
        });
        console.log("newres_data location => ", newres_data);
        setLocationsData(newres_data);
      }
    } catch (err) {
      console.log(err);
    }
    console.log("limitForLocationRecords => ", locationsLimit);
  };

  const getLocationsHandler = async () => {
    try {
      const isActiveQuery = isActive.length > 0 && isActive !== "Both" ? `isActive=${isActive}` : "";
      const locationValueQuery = locationName !== "" || locationName.length > 0 ? `&name=${locationName}` : "";
      const res_data = await axios.get(
        `${API_BASE_URL}/api/locations?${isActiveQuery}${locationValueQuery}&limit=20&page=${page}`
      );
      console.log("res_data.data location => ", res_data?.data);
      setLocationsTotal(res_data?.data?.total);
      setLocationsLimit(res_data?.data?.limit);
      if (res_data?.data?.results) {
        let newres_data = res_data?.data?.results.map((location, i) => {
          return { id: i + 1, ...location };
        });
        console.log("newres_data location => ", newres_data);
        setLocationsData(newres_data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLocationsHandler();
  }, [page]);

  const initialValuesObj = {
    name: "",
    checked: false,
  };

  const formik = useFormik({
    initialValues: initialValuesObj,
    onSubmit: async (values, { setSubmitting, setStatus, resetForm, error }) => {
      console.log("form location => ", values);
      try {
        const res_data = await axios.post(`${API_BASE_URL}/api/ns/locations`, values);
        console.log("res_location_added_data => ", res_data);
        if (res_data.status === 200) {
          handleClose2();
          Swal.fire({
            title: "Success!",
            text: " Location Added Successfully",
            icon: "success",
            confirmButtonText: "Close",
          });
          resetForm(initialValuesObj);
          setTimeout(() => getLocationsHandler(), 200);
        }
      } catch (e) {
        console.log(e);
        handleClose2();
        Swal.fire({
          title: "Error!",
          text: "Failed to add",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    },
  });

  return (
    <>
      <div className={cn(styles.category_wrapper)}>
        <div className={cn(styles.heading_wrapper)}>
          <h1>
            <span>Location List</span>
          </h1>
        </div>
        <div className={cn(styles.content_wrapper)}>
          <div className={cn(styles.form_content_wrapper)}>
            <div className={cn(styles.input_wrapper)}>
              <input
                type="search"
                name="name"
                placeholder="Search Location Name"
                required
                onChange={(e) => searchHandler(e.target.value)}
              />
              <Box sx={{ width: 140 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Active Status</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={isActive}
                    label="Active Status"
                    onChange={(e) => isActiveHandler(e)}
                  >
                    <MenuItem value="Both">
                      <em>Both</em>
                    </MenuItem>
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">Not Active</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className={cn(styles.button_wrapper)}>
              <Fab color="primary" aria-label="add" onClick={() => handleOpen2()}>
                <AddIcon />
              </Fab>
            </div>
          </div>
        </div>

        <div className={cn(styles.table_wrapper)}>
          <div style={{ position: "relative", paddingBottom: "80px" }}>
            {locationsData !== "" ? (
              <Box sx={{ width: "100%" }}>
                <StyledDataGrid
                  autoHeight
                  rows={locationsData}
                  columns={columns}
                  disableSelectionOnClick
                  hideFooterPagination={true}
                />
                <Pagination
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  count={Math.round(locationsTotal / locationsLimit)}
                  page={page}
                  onChange={(event, value) => {
                    console.log("value onChange => ", value);
                    setPage(value);
                  }}
                />
              </Box>
            ) : (
              <CircularProgress sx={styleCircular} />
            )}
          </div>
        </div>
      </div>
      {/* add location modal start from here  */}
      <div>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                mb: 5,
                pb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid black",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Location
              </Typography>
              <Button variant="outlined" size="small" onClick={handleClose2}>
                <FontAwesomeIcon icon={faClose} style={{ cursor: "pointer" }} />
              </Button>
            </Box>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <TextField
                id="outlined-basic"
                name="name"
                required
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Location Name"
                variant="outlined"
                fullWidth
                autoFocus
                size="small"
                sx={{ mb: 2 }}
              />
              <FormControl component="fieldset">
                <FormLabel component="legend">Active Status</FormLabel>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        name="checked"
                        checked={formik.values.checked}
                        onChange={formik.handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        color="primary"
                      />
                    }
                    label="isActive"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
              <br />
              <br />
              <Button type="submit" variant="contained" size="small" sx={{ mt: 1, mb: 3 }}>
                Add
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>

      {/* update location modal start from here  */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                mb: 5,
                pb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid black",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Update Location
              </Typography>
              <Button variant="outlined" size="small" onClick={handleClose}>
                <FontAwesomeIcon icon={faClose} style={{ cursor: "pointer" }} />
              </Button>
            </Box>
            <Box component="form" onSubmit={updateLocationHandler}>
              <TextField
                id="outlined-basic"
                name="name"
                required
                onChange={(e) => setSelectedRow({ ...selectedRow, name: e.target.value })}
                value={selectedRow.name}
                label="Location Name"
                variant="outlined"
                fullWidth
                autoFocus
                size="small"
                sx={{ mb: 2 }}
              />
              <FormControl component="fieldset">
                <FormLabel component="legend">Active Status</FormLabel>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        color="primary"
                      />
                    }
                    label="isActive"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
              <br />
              <br />
              <Button type="submit" variant="contained" size="small" sx={{ mt: 1, mb: 3 }}>
                Update
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Location;
