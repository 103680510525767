// import './App.css';
import { Routes, Route } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import Indicators from "./components/Indicator";
import Screenshots from "./components/Screenshot";
import Keywords from "./components/Keyword";
import Locations from "./components/Location";

function App() {
  return (
    <>
      <Routes>
        <Route index element={ <Dashboard><Indicators /></Dashboard>} />
        <Route path="/screenshots" element={ <Dashboard><Screenshots /></Dashboard>} />
        <Route path="/keywords" element={ <Dashboard><Keywords /></Dashboard>} />
        <Route path="/locations" element={ <Dashboard><Locations /></Dashboard>} />
      </Routes>
    </>
  );
}

export default App;
