import React, { useEffect, useState } from "react";
import styles from "./indicators.module.sass";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { numberWithCommas } from "../../utils";

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DashboardContent = () => {
  const [dashData, setDashboardRelatedData] = useState("");

  const getDashboardRelatedData = async () => {
    try {
      const res_data = await axios.get(`${API_BASE_URL}/api/utils/reports?reportId=dashboard`);
      if (res_data?.data) {
        console.log("DashboardRelatedData => ", res_data?.data);
        setDashboardRelatedData(res_data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDashboardRelatedData();
  }, []);

  return (
    <>
      <div className={styles.contentwrapper2}>
        {dashData.data &&
          dashData.data.map((v, i) => (
            <>
              <div className={styles.tabs2} key={i}>
                <div className={styles.categories2}>
                  <div className={styles.contentWrapper2}>
                    <span>Root Name:</span>
                    <span>{v.rootName}</span>
                  </div>
                  <div className={styles.contentWrapper2}>
                    <span>Posted Count:</span>
                    <span>{numberWithCommas(v.postedCount)}</span>
                  </div>
                  <div className={styles.contentWrapper2}>
                    <span>Failed Count:</span>
                    <span>{numberWithCommas(v.failedCount)}</span>
                  </div>
                  <div className={styles.contentWrapper2}>
                    <span>Success Ratio:</span>
                    <span>{v.successRatio}</span>
                  </div>
                  <div className={styles.contentWrapper2}>
                    <span>Failed Ratio:</span>
                    <span>{v.failedRatio}</span>
                  </div>
                  <div className={styles.contentWrapper2}>
                    <span>Total:</span>
                    <span>{numberWithCommas(v.total)}</span>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
      <div className={styles.contentwrapper}>
        <div className={styles.tabs}>
          <div className={styles.categories}>
            <h2>Grand Total</h2>
            <h4>{dashData?.grandTotal ? numberWithCommas(dashData.grandTotal) : ""}</h4>
          </div>
        </div>
        <div className={styles.tabs}>
          <div className={styles.categories}>
            <h2>Grand Posted Count</h2>
            <h4>{dashData?.grandPostedCount ? numberWithCommas(dashData.grandPostedCount) : ""}</h4>
          </div>
        </div>
        <div className={styles.tabs}>
          <div className={styles.categories}>
            <h2>Grand Failed Count</h2>
            <h4>{dashData?.grandFailedCount ? numberWithCommas(dashData.grandFailedCount) : ""}</h4>
          </div>
        </div>
        <div className={styles.tabs}>
          <div className={styles.categories}>
            <h2>Grand Success Ratio</h2>
            <h4>{dashData?.grandSuccessRatio ? dashData.grandSuccessRatio : ""}</h4>
          </div>
        </div>
        <div className={styles.tabs}>
          <div className={styles.categories}>
            <h2>Grand Failed Ratio</h2>
            <h4>{dashData?.grandFailedRatio ? dashData.grandFailedRatio : ""}</h4>
          </div>
        </div>
      </div>

      {/* chart started  */}
      <div className={styles.charts}>
        {dashData.data &&
          dashData.charts.map((chartItem, i) => (
            <div className={styles.circle} key={i}>
              <h2>{chartItem.datasets[0].label}</h2>
              <Doughnut data={chartItem} />
            </div>
          ))}
      </div>
    </>
  );
};

export default DashboardContent;
