// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard_contentcontainer__7H9VS {\n  width: calc(100% - 200px);\n  min-height: calc(100vh - 70px);\n  margin-left: 200px;\n  background-color: #eeeeee;\n}\n\n@media only screen and (max-width: 768px) {\n  .dashboard_contentcontainer__7H9VS {\n    width: calc(100% - 70px);\n    margin-left: 70px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/screens/Dashboard/dashboard.module.sass"],"names":[],"mappings":"AACA;EACI,yBAAA;EACA,8BAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;;AAEA;EAGI;IACI,wBAAA;IACA,iBAAA;EADN;AACF","sourcesContent":["\n.contentcontainer\n    width: calc(100% - 200px)\n    min-height: calc(100vh - 70px)\n    margin-left:200px\n    background-color: #eeeeee\n\n@media only screen and (max-width: 768px) \n    //For mobile phones\n\n    .contentcontainer\n        width: calc(100% - 70px)\n        margin-left:70px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentcontainer": "dashboard_contentcontainer__7H9VS"
};
export default ___CSS_LOADER_EXPORT___;
