import React, { useEffect, useState } from "react";
import styles from "./screenshots.module.sass";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { numberWithCommas } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const styleCircular = {
  position: "absolute",
  top: {
    xs: "50%",
  },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20px",
  height: "20px",
};

const ScreenshotsContent = () => {
  const [screenshotsData, setscreenshotsData] = useState("");
  const getScreenshotData = async () => {
    try {
      //FETCH Screenshot Data
      const resp = await axios.get(`${API_BASE_URL}/api/utils/reports?reportId=screenshots`);
      if (resp?.data) {
        console.log("screenshotData => ", resp?.data);
        setscreenshotsData(resp?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getScreenshotData();
  }, []);

  return (
    <>
      <div className={(styles.mainheader)}>
        <h1>
          <span>Screenshots Report</span>
        </h1>
        <span>last 7 days</span>
      </div>

      <div className={styles.contentwrapper2}>


        {screenshotsData.totalDone ? (
          <>
            <div className={styles.tabs2} key="totalDone">
              <div className={styles.categories2}>
                <div className={styles.contentWrapper2}>
                  <span>Total Done:</span>
                  <span>{numberWithCommas(screenshotsData.totalDone)}</span>
                </div>
              </div>
            </div>
          </>
        ) :
          <CircularProgress sx={styleCircular} />}

        {screenshotsData.totalDone && (
          <>
            <div className={styles.tabs2} key="totalTodo">
              <div className={styles.categories2}>
                <div className={styles.contentWrapper2}>
                  <span>Total Pending:</span>
                  <span>{numberWithCommas(screenshotsData.totalTodo)}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* chart started  */}
      <div className={styles.charts}>
        {screenshotsData &&
          screenshotsData.charts.map((chartItem, i) => (
            <div className={styles.circle} key={i}>
              <h2>{chartItem.datasets[0].label}</h2>
              <Bar data={chartItem} />
            </div>
          ))}
      </div>
    </>
  );
};

export default ScreenshotsContent;
